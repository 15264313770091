.contest {
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 70px;
  overflow: hidden;
}

.contest__container {
  margin-top: 100px;
  position: relative;
}

.contest__text-box {
  width: 190px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 86px;
}

.contest__text-box_dark {
  border: 1px solid var(--header-font-color);
}

.contest__text {
  font-size: 1.5em;
  color: #fff;
}

.contest__text-box_dark > .contest__text {
  color: var(--page-font-color);
}

.contest__text-roteted {
  transform: rotate(270deg);
  font-size: 1.2em;
  margin-right: -12px;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.contest__text-box:hover .contest__text-roteted {
  transform: rotate(360deg);
  margin-right: 5px;
}

.contest__container .contest__slider-btn_dark > svg {
  color: var(--page-font-color);
}

.contest__img {
  max-height: 90vh;
  object-fit: contain;
}

@media (max-width: 768px) {
  .contest__container {
    margin-top: 191px;
  }

  .contest__text-box {
    width: 100%;
    border: none;
    top: -110px;
    right: auto;
    left: 0;
  }

  .contest__text {
    color: var(--page-font-color);
  }
}

