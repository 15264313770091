.arrow__container {
  display: flex;
  height: 70vh;
  width: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: all 0.3s;

  /* slider test */
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translate(0, -50%);
  background: none;
  border: none;
}

.arrow__container:hover {
  background: #18181878;
  cursor: pointer;
}

.arrow__container_right {
  right: 0;
}

.arrow__container_left {
  left: 0;
}

.arrow {
  width: 27px;
  height: 27px;
  border-top: 2px solid var(--task-text-color);
  border-right: 2px solid var(--task-text-color);
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.arrow:hover {
  opacity: 0.8;
}

.arrow-right {
  transform: rotate(45deg);
}

.arrow-left {
  transform: rotate(-135deg);
}

@media (max-width: 768px) {
  .arrow__container:hover {
    background: none;
  }
}
