.image-popup {
  display: flex;
  background-color: rgba(1,1,1,.8);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 5;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}

.image-popup_opened {
  visibility: visible;
  opacity: 1;
}

.image-popup__container {
  background-color: #fff;
  padding: 30px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 230px;
  grid-gap: 40px;
  gap: 40px;
  position: relative;
}

.image-popup__close-btn {
  position: absolute;
  background: none;
  border: none;
  outline: none;
  padding: 5px;
  top: 10px;
  right: 10px;
  display: flex;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s;
}

.image-popup__close-btn:hover {
  background-color: #f3f4f5;
}

.image-popup__close-btn > svg {
  width: 25px;
  color: var(--page-font-color);
  height: 25px;
}

.image-popup__text {
  margin: 0;
  width: 213px;
  line-height: 1.4em;
  font-size: 1em;
  font-weight: 300;
  transition: opacity 0.5s 0.2s;
  opacity: 0;
  visibility: hidden;
}

.image-popup_opened .image-popup__text {
  opacity: 1;
  visibility: visible;
}

.image-popup__img {
  width: 100%;
  max-height: 90vh;
}

@media (max-width: 580px) {

  .image-popup {
    min-height: 100%;
    overflow: auto;
  }

  .image-popup__text {
    width: auto;
    margin: 0 auto;
  }

  .image-popup__container {
    min-height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 43px 15px 30px;
    position: absolute;
    top: 0;
  }

  .image-popup__close-btn {
    top: 7px;
    right: 7px;
  }
}
