.main {

}

.main__promo-box {
  position: absolute;
  top: 32%;
  left: 23%;
}

.main__promo-text {
  color: #fff;
  font-size: 1.5em;
  margin: 0;
  line-height: 1.5em;
  animation: slideInRight 0.3s ease-out;
}


.main__pictures {
  /* max-width: var(--max-width);
  width: var(--content-width); */
  margin: 0 auto var(--main-gap);
  animation: opaccity 1s;
  padding-top: var(--main-gap);
}

.main__btn {
  text-decoration: none;
  color: inherit;
  margin-top: 20px;
  margin: 20px auto 0;
  display: flex;
  font-size: 17px;
  justify-content: center;
  max-width: 130px;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 7px;
  transition: var(--standart-transition);
  background: #eff3f2;
  visibility: hidden;
}

.main__btn_active {
  visibility: visible;
}

.main__btn:hover {
  background: var(--page-font-color);
  color: #fff;
}

.main__heading {
  margin-bottom: var(--main-gap);
  margin-top: 0;
  text-align: center;
  font-weight: 200;
  transition: all 0.3s;
}

.main__heading:hover {
  transform: translateY(-10%);
}


.filter__btn {
  position: fixed;
  /* z-index: 5; */
  margin: 0;
  background-color: rgb(35 35 35 / 70%);
  color: white;
  border-radius: 10px;
  font-size: 13px;
  transform: translate(-50%);
  top: 14%;
  left: 50%;
  padding: 6px;
  cursor: pointer;
  display: none;
  transition: all 0.3s;
}

.filter__btn:hover {
  background-color: rgb(35 35 35 / 90%);
}

.filter__btn_active {
  display: block;
}

.main__img-row:nth-of-type(1) {
  grid-template-columns: calc(70% / 3) 30% calc(70% / 3);
}

.main__img-row:nth-of-type(2) {
  grid-template-columns: 55% calc(70% / 2);
}

.main__img-row:nth-of-type(3) {
  grid-template-columns: calc(60% / 2) 60%;
}

.main__img-row:nth-of-type(4) {
  width: 100%;
}

.main__img-row:nth-of-type(5) {
  grid-template-columns: 35% 50%;
}

.main__img-row:nth-of-type(6) {
  grid-template-columns: 35% 50%;
}

.main__img-row:nth-of-type(7) {
  grid-template-columns: 35% auto 35%;
}

.main__img-row:nth-of-type(8) {
  grid-template-columns: 30% 30% 30%;
}

.main__img-row:nth-of-type(9) {
  width: 100%;
}

.main__img-row:nth-of-type(10) {
  width: 100%;
  margin-top: calc(var(--grid-row-gap) * -1);
  margin-bottom: calc(var(--grid-row-gap) * -1);
}

.main__img-row:nth-of-type(11) {
  width: 100%;
}

.main__img-row:nth-of-type(12) {
  grid-template-columns: 50% 40%;
}

.main__img-row:nth-of-type(13) {
  width: 86%;
}

.main__img-row:nth-of-type(14) {
  grid-template-columns: 50% 40%;
}

.main__img-row:nth-of-type(15) {
  grid-template-columns: 20% auto 40%;
}

.main__img-row:nth-of-type(16) {
  grid-template-columns: 32% 53%;
}

.main__img-row:nth-of-type(17) {
  grid-template-columns: 25% 35% 30%;
}

.main__img-row:nth-of-type(18) {
  grid-template-columns: 25% auto 45%;
}

.main__img-row:nth-of-type(19) {
  grid-template-columns: 15% 45% 15%;
}

.main__img-row:nth-of-type(20) {
  grid-template-columns: 30% 30% 30%;
}

.main__img-row:nth-of-type(21) {
  width: 100%;
}

.main__img-row:nth-of-type(22) {
  width: 100%;
  margin: -2em 0 -2em;
}

.main__img-row:nth-of-type(23) {
  width: 100%;
}

.main__contest-box {
  position: absolute;
  top: 21%;
  left: 10px;
  display: inline-flex;
  gap: 5px;
  color: #fff;
  text-decoration: none;
  align-items: center;
  transition: opacity 0.3s;
  opacity: 0.6;
}

.main__contest-box:hover {
  opacity: 1;
}

.main__contest-box > span {
  background-image: url('../../images/light/link.svg');
  background-position: center;
  background-size: cover;
  width: 15px;
  height: 15px;
  display: block;
}


@media (max-width: 768px) {
  /* .main__pictures {
    column-count: 2;
  } */

  .main__pictures .main__img-row {
    grid-template-columns: 1fr;
    gap: 4em;
    justify-items: center;
  }
}

@media (max-width: 580px) {
  /* .main__pictures {
    column-count: 1;
  } */

  .main__promo-box {
    left: 6%;
  }

  .main__promo-text {
    font-size: 1.3em;
  }
}

