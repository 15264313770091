.promo {

}

.promo__container {
  position: relative;
}

.promo__img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  display: block;
}

.promo__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0000007d;
}

.promo__text-box {
  position: absolute;
  top: 32%;
  left: 23%;
}

.promo__text {
  color: #fff;
  font-size: 1.5em;
  margin: 0;
  line-height: 1.5em;
}
