.header {
  position: absolute;
  width: 100%;
  z-index: 5;
}

.header_init {
  top: 2%;
}

.header_transition {
  transition: top 0.4s;
}

.header_fixed {
  position: fixed;
  top: 0%;
  background: #000000c2;
}

.header_hidden {
  position: fixed;
  top: -20%;
  background: #000000c2;
}

.header__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  transition: all 0.3s ease;
  position: relative;
  margin: 0 auto 0;
  z-index: 5;
  animation: headerFix 0.3s;
  max-width: var(--max-width);
  width: var(--content-width);
  color: #fff;
}

.header__logo {
  width: 150px;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .body_unscrolled .header {
    width: calc(100% - 17px);
  }
}

@media (max-width: 768px) {
  .header__container {
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    width: 90%;
  }

  .header__container_fixed {
    justify-content: space-between;
  }

  .header__logo {
    max-width: 120px;
  }

  .header_init {
    top: 2%;
  }

  .header_active-menu {
    top: 2%;
    position: fixed;
  }
}

@keyframes headerFix {
  from {
    transform: translateY(-100%);
  }

  top {
    transform: translateY(0);
  }
}
