.contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.3em;
  flex-wrap: wrap;
}

.contacts__link {
  transition: var(--standart-transition);
  display: none;
 /*  margin-left: 1.3em; */
}

/* .contacts__link:first-of-type {
  margin-left: 0;
} */

.contacts__link_active {
  display: flex;
  align-items: center;
}

/* .contacts__link_dim {
  opacity: 0.4;
} */

.contacts__link:hover {
  opacity: 0.6;
}


.contacts__link_dim:hover {
  opacity: 0.9;
}

.contacts__img {
  display: block;
  width: 30px;

}

.contacts__img > path {
  fill: #797979;
}

.contacts__name {
  display: none;
}

.contacts__name_active {
  display: block;
  margin-left: 5px;
}

/* .contacts__img_cut {
  border-radius: 11px;
} */

