.usage {

}

.usage__pictures {
  padding: var(--main-gap) 0 var(--main-gap);
}

.usage__promo-text {
  color: #fff;
  font-size: 1.5em;
  margin: 0;
  line-height: 1.5em;
  animation: slideInTop 0.3s;
}

.usage__promo-box {
  position: absolute;
  top: 48%;
  left: 31%;
}

.usage__img-row:nth-of-type(1) {
  grid-template-columns: auto 30% 30%;
}

.usage__img-row:nth-of-type(2) {
  grid-template-columns: 30% 30%;
}

.usage__img-row:nth-of-type(3) {
  grid-template-columns: 30% auto 30%;
}

.usage__img-row:nth-of-type(4) {
  grid-template-columns: 25% 25% 25%;
}

.usage__img-row:nth-of-type(5) {
  grid-template-columns: 20% 20% auto;
}

.usage__circle {
  width: 170px;
}

.usage__link {
  position: absolute;
  top: 11%;
  left: 12%;
  margin: 0;
  opacity: 1;
  z-index: 5;
}

.usage__link_inactive {
  display: none;
}

.usage__img-link {
  margin-left: 10px;
}

@media (max-width: 768px) {

  .cards-container .usage__img-row {
    grid-template-columns: 1fr;
    gap: 4em;
    justify-items: center;
  }

  .usage__img-link {
    display: flex;
    top: 5%;
    left: 3%;
    margin: 0;
    opacity: 1;
    position: absolute;
  }
}

@media (max-width: 580px) {

  .usage__link {
    width: 40px;
    height: 40px;
  }

  .usage__img-link {
    width: 40px;
    height: 40px;
  }

}


