.card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.card_scaled {
  padding: 20px;
  transform: scale(1.5);
  background: #000000b3;
  z-index: 1;
}

.card_scaled > .card__overlay {
  transform: scale(0.7);
}

.card_typed {
  animation: typing 5s steps(60) infinite, blink-caret .7s step-end infinite;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  border-right: 1px solid var(--page-font-color);
  margin: 0 auto;
}

.card_layout {
  cursor: pointer;
}

.card_layout:hover .card__overlay {
  transform: translateY(-10px);
}

.card_layout:hover .card__img {
  transform: translateY(-10px);
}

.card_scaled:hover .card__overlay {
  transform: scale(0.7);
}

.card_scaled:hover .card__img{
  transform: scale(1.07);
}

.card__overlay {
  position: absolute;
  /* opacity: 0; */
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.2s all linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__overlay_inactive {
  display: none;
}

.card__overlay-icon {
  background: #0000008f;
  border-radius: 30px;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: var(--standart-transition);
  cursor: pointer;
}

.card__overlay-icon:hover {
  background: #000000cc;
}

.card__overlay-icon_inactive {
  display: none;
}

.card__overlay-link {
  margin-left: 10px;
  display: none;
}

.card__overlay-link_active {
  display: flex;
}

.card__overlay-link > a {
  display: flex;
}

.card__img {
  width: 100%;
  display: block;
  transition: var(--standart-transition);
}

.card > p > span {
  font-weight: 600;
  font-family: "Archivo Black", "Raleway", Sans-serif, Arial;
  color: #dfd7a3;
}

.card__circle-wrapper {
  position: relative;
  display: inline-block;

}

.card__circle {
  animation: rotate 8s infinite linear;
  width: 210px;
  transition: all 0.3s;
}

.card__circle-text {
  font-size: 8px;
  font-weight: 400;
  transition: all 0.3s;
}

.card__circle-wrapper:hover .card__circle-text {
  opacity: 0.5;
}

.card__circle-title {
  position: absolute;
  transform: translate(-50%, -50%) rotate(15deg);
  left: 50%;
  top: 50%;
  font-size: 2.6em;
  font-family: "Archivo Black", "Raleway", Sans-serif, Arial;
  color: #dfd7a3;
  transition: var(--standart-transition);
}

.card__circle-wrapper:hover .card__circle-title {
  transform: translate(-50%, -50%) rotate(0);
}

.card__vertical-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Archivo Black", "Raleway", Sans-serif, Arial;
}

.card__vertical-text > span {
  writing-mode: vertical-rl;
  text-orientation: upright;
  margin-right: 10px;
  letter-spacing: -4px;
}

.card__vertical-text > p {
  font-size: 1.6em;
}

.card__text {
  font-size: 1.5em;
  line-height: 1.3em;
  width: 100%;
  margin: 0;
  width: auto;
}

.card__inside-text {
  font-size: 1.1em;
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  text-align: center;
  background: #f7f7f58f;
}

.card__large-text {
  position: relative;
}

.card__large-text > p {
  text-align: center;
  margin: 0;
  font-family: "Archivo Black", "Raleway", Sans-serif, Arial;
  color: #dfd7a3;
  font-size: 2.7em;
}

.card__large-text > span {
  position: absolute;
  writing-mode: vertical-rl;
  transform: translateX(-50%) rotate(180deg);
  font-size: 1.1em;
  font-weight: 300;
  left: 50%;
}

.card__large-text > p > span {
  display: block;
  overflow: hidden;
  animation: typing 3s steps(35) infinite, blink-caret .7s step-end infinite;
  border-right: 2px solid #dfd7a3;
  margin: 0 auto;
}

.card__text_inside {
  display: none;
}

@media (max-width: 768px) {

  .card_small {
    width: 80%;
  }

  .card_scaled {
    transform: none;
  }

  .card_scaled > .card__overlay {
    transform: none;
  }

  .card_scaled:hover .card__overlay {
    transform: scale(1);
  }

  .card_scaled:hover .card__img{
    transform: scale(1.02);
  }

  .card_typed {
    max-width: var(--content-width);
    text-align: center;
    animation: none;
    white-space: normal;
    border: none;
  }

  .card__text {
    font-size: 1.3em;
  }

  .card__inside-text {
    display: none;
  }

  .card__text_inside {
    display: block;
  }

}


@media (hover: hover) and (pointer: fine) {
  .card_layout:hover .card__overlay .card__overlay-icon {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
}

@keyframes typing {
  0% {
    width: 0;
  }
  80% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent
  }
  50% {
    border-color: currentColor;
  }
}

@keyframes t{
  from {background-size:0 200%}
}
@keyframes b{
  50% {background-position:0 -100%,0 0}
}


