:root {
  --page-background: rgb(231 229 229);
  --header-font-color: #bdc1c6;
  --page-font-color: #5b5b5b;
  --main-font: 15px;
  --standart-transition: all 0.2s linear;
  --content-width: 98%;
  --header-height: 50px;
  --task-text-color: #8B8B8B;
  --task-background: #3c3c3c;
  --light-grey: #e4e4e4;
  --link-color: #4685de;
  --header-width: 760px;

  --max-width: 1300px;
  --section-margin: 40px;
  --header-height: 180px;
  --mouse-width: 45px;
  --site-gap: 22px;
  --main-gap: 55px;
  --grid-row-gap: 5em;
}

.page {
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  font-size: var(--main-font);
  color: var(--page-font-color)
}

.page__wrapper {
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.link {
  transition: opacity 0.3s linear;
}

.link:hover {
  opacity: 0.6;
}

.body_unscrolled {
  overflow: hidden;
}


@keyframes fadeinPart {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeinFull {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translate(-100%);
  }
  to {
    transform: translate(0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translate(100%);
  }
  to {
    transform: translate(0);
  }
}

@keyframes slideInTop {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}


@media (min-width: 768px) {
  /* .page {
    padding: 0;
  }

  .page__wrapper {
    padding: 0 10px;
  } */

  .body_unscrolled {
    padding-right: 17px;
  }

}
