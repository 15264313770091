nav {
  width: 100%;
  display: flex;
  justify-content: center;
}

.menu__ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  position: relative;

  width: var(--content-width);
  /* max-width: var(--header-width); */
  justify-content: space-between;
  margin: 0;
  padding: 5px;
}

.menu__li {
  text-decoration: none;
  color: #fff;
  padding-bottom: 5px;

  transition: opacity 0.3s linear;
  font-size: 1.6em;
  display: flex;
}

.header__container_dark .menu__li {
  color: #000;
}

.menu_active .menu__li {
  color: #fff;
}

.menu__li-logo {
  display: none;
}

.menu__li > img {
  width: 5em;
}

.header__container_fixed .menu__li {
  color: #fff;
}

.menu__li_active {
  /* color: #000; */
  border-bottom: 1px solid;
}

.menu__li:hover {
  opacity: 0.6;
}

.burger {
  display: none;
}

.menu__sub-ul {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 200px;
  background-color: rgb(35 35 35 / 90%);
  padding: 15px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s linear;
  list-style-type: none;
  z-index: 1;
  border-radius: 7px;
}

.menu__ul > li._active .menu__sub-ul {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.menu__ul > li._active .menu__arrow {
  transform: rotate(-45deg);
}

.menu__sub-li {
  color: #c5c5c5;
  text-decoration: none;
  transition: all 0.2s ease;
  cursor: pointer;
}

.menu__sub-li:hover {
  opacity: 0.5;
}

.menu__arrow {
  display: block;
  transition: transform 0.3s ease;
  margin: 0 0 0 9px;
  width: 7px;
  height: 7px;
  border-top: 1px solid #5f5c5c;
  border-right: 1px solid #5f5c5c;
  transform: rotate(135deg);
}

.menu__ul > li {
  display: flex;
  align-items: center;
  /* margin-left: 5em; */
}

/* .menu__ul > li:first-of-type {
  margin-left: 0;
} */

.menu__sub-ul > li {
  margin-top: 10px;
}

.menu__sub-ul > li:first-of-type {
  margin-top: 0;
}

@media (max-width: 768px) {
  .menu {
    position: fixed;
    top: -100%;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #131212f2;
    transition: all 0.2s linear;
    z-index: 4;
    overflow: hidden;
    max-width: 70%;
    visibility: hidden;
  }

  .menu_active {
    top: 0;
    visibility: visible;
  }

  .menu__ul {
    flex-direction: column;
    z-index: 5;
    margin-top: 35%;
    align-items: center;
    justify-content: flex-start;
  }

  .menu__logo {
    order: -1;
    margin-bottom: 2.8em;
  }

  .menu__ul > li {
    margin-bottom: 2.5em;
    display: block;
    position: relative;
  }

  .menu__ul > li:last-child {
    margin-bottom: 0;
  }

  .menu__li {
    color: var(--light-grey);
    /* margin-right: 25px; */
    font-size: 1.4em;
    padding: 0;
  }

  .menu__li-logo {
    display: block;
  }

  .menu__li > img {
    width: 4.5em;
  }

  .menu__arrow {
    position: absolute;
    top: 7px;
    left: 112px;
  }

  .menu__sub-ul {
    position: relative;
    max-width: 50%;
    margin-left: 46px;
    display: none;
  }

  .menu__ul > li._active .menu__sub-ul {
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 11%);
    pointer-events: all;
    display: block;
    top: 0;
  }

  .menu__ul > li._active .menu__arrow {
    transform: rotate(-45deg);
  }

  .menu__sub-li {
    color: #c5c5c5;
  }



}

@media (max-width: 580px) {
  .menu {
    max-width: 100%;
  }
}
