.popup-image__container {
  width: 100%;
}

.popup-image {
  display: none;
  transition: background-color 0.3s;
  position: absolute;
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
}

.popup_opened {
  display: flex;
  background-color: rgba(1,1,1,.8);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 5;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding-right: ScrollBarWidth px;
}

.popup-image__slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-image__picture {
  width: 80vw;
  max-height: 80vh;
  max-width: 1000px;
  /* transform: translateX(0);
  transition: transform 0.3s linear; */
  animation: fadein 1s ease-in 0.1s forwards;
}

/* .popup-image__picture_active {
  transition: transform 0.3s linear;
  transform: translateX(0);
  visibility: visible;
  pointer-events: none;
} */


.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 32px;
  height: 32px;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  }

.close:hover {
  opacity: 0.8;
  cursor: pointer;
}

.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 3px;
  background-color: #fff;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.btn-cross {
  border: none;
  display: flex;
  align-items: center;
  padding: 3px 5px;
  background: var(--task-background);
  border-radius: 5px;
  margin-left: 10px;
  transition: var(--standart-transition);
}

.btn-cross:hover {
  background: #18181878;
  cursor: pointer;
}

.btn-cross svg {
  display: block;
  transform: rotate(45deg);
  width: 8px;
}

.btn-cross svg line {
  stroke: var(--task-text-color);
  stroke-width: 5;
}

.btn-cross span {
  color: #8B8B8B;
  font-size: 14px;
  margin-left: 5px;
}

.popup__close-btn {
  position: absolute;
  top: 20px;
  right: 30px;
  padding: 14px;
  border-radius: 50%;
  background: none;
  transition: var(--standart-transition);
  transform: rotate(45deg);
}

.popup__close-btn svg {
  width: 20px;
}

.slider__card {
  cursor: auto;
  width: auto;
}

.slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider__img {
  margin: auto;
  width: auto;
  max-width: 80%;
  max-height: 90vh;
  cursor: auto;
}

  /* the slides */
  .slick-slide {
    margin: 0 27px;
}

/* the parent */
.slick-list {
    margin: 0 -27px;
}

.slider__img-box {
  position: relative;
}

@media (max-width: 580px) {

  /* .arrow__container {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 40px;
    border-radius: 0;
  }

  .arrow__container:hover {
    background: #0000003b;
    cursor: pointer;
  }

  .arrow {
    width: 20px;
    height: 20px;
    border-top: 1px solid #00000030;
    border-right: 1px solid #00000030;
  } */

  .popup-image__slider {
    position: relative;
    justify-content: center;
  }

  /* .arrow__container_right {
    right: 0;
  }

  .arrow__container_left {
    left: 0;
  } */

  /* .popup-image__picture {
    width: 100%;
  }
 */
  .slider__img {
    max-width: 100%;
  }

}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

