.about {
  /* animation: opaccity 0.5s; */
  overflow-x: hidden;
  /* animation: fadein 1s; */
}

.about__block {
  display: flex;
  width: 100%;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.about__text {
  margin: 0;
  font-size: 1.5em;
  line-height: 1.5em;
  letter-spacing: 1px;
}

.about__text_afterward {
  margin: var(--main-gap) 0 30px;
}

.dark-box {
  padding-top: 8em;
}

.dark-box_small {
  padding: 0;
}

.dark-box__img-cont {
  position: relative;
  display: flex;
}

.dark-box__back-img_left {
  height: 70vh;
  position: absolute;
  top: -163%;
  left: -138%;
  animation: fadeinFull 0.5s;
}

.dark-box__back-img_small {
  height: 70vh;
  position: absolute;
  display: none;
  left: 0;
  bottom: 5%;
  top: auto;
}

.dark-box__back-img_right {
  height: 37vh;
  position: absolute;
  bottom: 2%;
  right: 5%;
}

.dark-box__photo {
  position: absolute;
  z-index: 1;
}

.dark-box__photo_main {
  height: 55vh;
  position: relative;
  display: block;
  /* top: -10em;
  left: -19em; */
}

.dark-box__photo_back {
  height: 34vh;
  bottom: -18%;
  left: -19%;
}

.dark-box__text {
  color: #fff;
  z-index: 1;
  position: relative;
  left: 13%;
}

.dark-box__text:first-of-type {
  animation: slideInLeft 0.3s ease-out;
}

.dark-box__text_small {
  left: 0;
}

.dark-box__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000a3;
  top: 0;
}

.dark-box__img-cont_small {
  display: grid;
  align-items: center;
  position: relative;
  grid-template-columns: auto auto;
  gap: 3em;
}

.dark-box__img-cont_small > div {
  position: relative;
}

.light-box {
  flex-direction: column;
}

.light-box__img-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.light-box__img-cont > img {
  height: 50vh;
  margin: 0px 0 60px 138px;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  object-fit: cover;
  object-position: top;
}


.light-box__text {
  margin-bottom: 10px;
}

.about__contacts {
  margin-top: var(--main-gap);
  padding-bottom: 30px;
}

.about__contacts > p:first-of-type {
  text-align: center;
  margin-bottom: 15px;
}

.about__contacts > p:last-of-type {
  text-align: center;
  margin-top: 40px;
}

@media (max-width: 768px) {

  .light-box__img-cont > img {
    margin: 0px 0 50px 50px;
  }

  .dark-box__img-cont_small {
    grid-template-columns: auto;
    justify-items: center;
    gap: 5em;
  }

  .dark-box__back-img_right {
    right: 0;
  }

}

@media (max-width: 580px) {

  .dark-box__photo_main {
    height: 48vh;
  }

  .dark-box__back-img_left {
    display: none;
  }

  .dark-box__back-img_small {
    display: block;
  }

  .dark-box__text {
    left: 0;
  }

  .about__text {
    font-size: 1.3em;
  }

  .light-box {
    padding: 30px 0;
  }

  .light-box__img-cont > img {
    margin: 20px 0 20px;
    order: -1;
  }

  .light-box__img-cont {
    flex-direction: column;
  }

  .dark-box__photo_back {
    height: 28vh;
    bottom: -18%;
    left: -15%;
  }

  .dark-box__back-img_right {
    right: -13%;
    height: 29vh;
  }

}

/* @keyframes opaccity {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
} */

