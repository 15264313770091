.popupResult {
  width: 100%;
  height: 100%;
  background-color: rgba(1,1,1,.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: visibility 0.1s, height 0.3s;
}

.popupResult_inactive {
  visibility: hidden;
  height: 0%;
}

.popupResult__container {
  padding: 30px;
  background: #e4e4e4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.popupResult__img {
  width: 70px;
}

.popupResult__heading {
  font-weight: 500;
}

.popupResult__btn {
  border: none;
  width: 70px;
  height: 35px;
  border-radius: 10px;
  background: #b7b7b7;
  transition: background 0.3s;
}

.popupResult__btn:hover {
  cursor: pointer;
  background: #909090;

}
