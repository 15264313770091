.contest-slider-arrow {
  background: none;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s;
  z-index: 4;
  opacity: 0.2;
}

.contest-slider-arrow_left {
  right: auto;
  left: 0;
}

.contest-slider-arrow:hover {
  opacity: 0.7;
}

.contest-slider-arrow svg {
  color: #fff;
  width: 25px;
  height: 25px;
  display: block;
}
